import $ from 'jquery';
import Scrolling from '../libs/Scrolling';

export default {

    init: () => {
        if ($("[data-accordion]").length > 0) {

            $("[data-accordion] .head").on('click', function (event) {
                event.preventDefault();

                if ($(this).parent().hasClass('open')) {
                    $(this).parent().find('.body').slideUp();
                    $(this).parent().removeClass('open');
                } else {
                    $(this).parent().parent().find('.body').slideUp();
                    $(this).parent().parent().find('li').removeClass('open');


                    $(this).parent().addClass('open');

                    $(this).parent().find('.body').slideDown();


                }

            });

        }
    },


};