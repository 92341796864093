import $ from 'jquery';
import './Ease-Plugin';
import {breakpointUp} from './Breakpoints';
// Initialiser en faisant Scrolling.init( #Sélecteur css du menu )
// Scrolling.scrollOnLoad() Si on veut que ça scroll sur l'ancre au chargement de la page

/**
 *
 * @param {String} menuSelect => Nom du selecteur du menu du site                                (default : aucun)
 *   @param {int} vitesse         => Vitesse de scroll vers l'élément cible                  (default : 900 ms)
 *   @param {int} delay               => Delay avant le début du scoll vers la cible         (default : 500 ms)
 *
 */

let bringerScrolling = {
    hauteurMenu: 0,
    vitesse: 1000,
    delay: 500,
    selecteurMenu: 'header .nav-bar',

    // Valeur actuelle taille écran
    init: function (menuSelect, vitesse, delay) {
        if (vitesse) {
            this.vitesse = vitesse;
        }
        if (delay) {
            this.delay = delay;
        }

        this.hauteurMenu = $(this.selecteurMenu).height();
        let w = $(window).width();

        $(window).resize(() => {
            if ($(window).width() == w) return;
            w = $(window).width();
            this.refreshValue();
        });


    },

    refreshValue: function () {
        this.hauteurMenu = $(this.selecteurMenu).height();
    },

    scrollTo: function (idcible, ease, speed, moreOffset = 0) {
        this.refreshValue();
        let p = $(idcible);
        let offset = p.offset();
        let vitesse = this.vitesse;
        if (speed) {
            vitesse = speed;

        }
        
        let laposition = offset.top - moreOffset ;

        if (laposition <= 0) {
            laposition = 0;
        }


        $('html, body').animate({
            scrollTop: laposition
        }, vitesse, ease, function () {

            // Add hash (#) to URL when done scrolling (default click behavior)
            //window.location.hash = hash;
        });

    },
    scrollOnLoad: function () {

        if (location.hash) {
            setTimeout(function () {
                window.scrollTo(0, 0);
            }, 1);
        }

        let hash = window.location.hash;
        if (hash != '') {
            //regulatory documents
            setTimeout(() => {
                this.scrollTo(hash);
            }, this.delay);
        }
    },
    hashScroll: function (cible) {

        // Gestion des scroll to ancres
        let el = $(cible);
        el.on('click', function (event) {

            // Make sure this.hash has a value before overriding default behavior
            if (this.hash !== "") {
                // Prevent default anchor click behavior
                event.preventDefault();

                // Génère un Event si besoin
                $(bringerScrolling).trigger('clickHashLink');

                // Store hash
                var hash = this.hash;
                bringerScrolling.scrollTo(hash);

            }  // End if
        });
    }


};


export default bringerScrolling;
