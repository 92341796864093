<template>
    <a href @click.prevent=scrollTo>
        <slot></slot>
    </a>
</template>

<script>
    import Scrolling from '../libs/Scrolling'

    export default {
        props: {
            idcible: {
                type: String,
                default: 'section.destination-infos',
            },
            ease: {
                type: String,
                default: 'easeOutQuint',
            },
            speed: {
                type: Number,
                default: 900,
            },
            offset: {
                type: Number,
                default: 0,
            },
        },
        data() {
            return {};
        },
        methods: {
            scrollTo() {
                Scrolling.scrollTo(this.idcible, this.ease, this.speed, this.offset)
            }

        },
        created() {

        },
    }
</script>
