// Vendors
import $ from 'jquery';
window.jQuery = $
// Vue Components
import './components';

// Personnal Libs
// import BarbaJs from './libs/BarbaJs';
import VueContent from './libs/VueContent';
import VueHeader from './libs/VueHeader';
import Scrolling from './libs/Scrolling';
import {breakpoint} from './libs/Breakpoints';
import majTailleVideo from './libs/MediaResponsive';
import SvgReplace from './libs/Svg-Replace';
import KeyAnim from './libs/KeyAnim';
import ParallaxJs from './libs/Parallax';
import ContactForm from './libs/Contact-form';
import ScrollMagic from './libs/ScrollMagic';
import Swiper from './libs/Swiper';
import Accordion from './libs/Accordion';
import HomeVideoAnim from './libs/HomeVideoAnim';
import Menu from './libs/menu';
import Video from './libs/Video';
import Video2 from './libs/Video2';



$(() => {
    breakpoint.init();
    Scrolling.init();
    window.mainVue = new VueContent();
    window.headerVue = new VueHeader();
    ContactForm.init();
    ScrollMagic.init();
    KeyAnim.init();
    SvgReplace.init();
    ParallaxJs.init();
    Swiper.init();
    Accordion.init();
    HomeVideoAnim.init();
    Menu.init();
    Video.init();
    Video2.init();
    //SwiperFocus.init();

    // new BarbaJs();
    // SmoothScroll.init();




    $(window).resize(function () {
        majTailleVideo('video-wrapper', 1.7777);
    }).resize();


    if($('body').hasClass('page-template-gabarit-A')){
        var nb = $('.sub-menu.menu-inter li').length;
        if(nb <= 1){
            $('.sub-menu.menu-inter').hide(0);
        }
    }
});
