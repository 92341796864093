import $ from 'jquery';
import Swiper from 'swiper';
import {breakpointUp} from './Breakpoints';

let swiper = {

    parallaxInstance: null,

    init() {


        // $(".page-template-gabarit-A .swiper-container").each(function(){
        //     if($('.swiper-slide',this).length == 1) {
        //         $(this).addClass('no-swiper');
        //         $(this).removeClass('swiper-container');
        //     }
        // })

        $('body').hasClass('page-template-gabarit-B') ? [this.getSwiper()] : null;
        $('body').hasClass('page-template-gabarit-C') ? [this.getSwiper()] : null;
        $('body').hasClass('page-template-gabarit-D') ? [this.getSwiper()] : null;
        $('body').hasClass('home') ? [this.getSwiper()] : null;
        $('body').hasClass('page-template-gabarit-A') ? [this.getSwiper()] : null;


    },

    getSwiper() {
        this.initSwiper();

        $(window).bind('resizeEndThrottle', () => {
            this.resizeSlide();
        });

        $(document).ready(() => {
            this.resizeSlide();
        });

    },


    initSwiper() {

        return new Swiper('.swiper-container', {
            // direction: 'vertical',
            // autoHeight: true,
            effect: 'fade',
            fadeEffect: {
                crossFade: true
            },
            autoplay: {
                delay: 4000,
            },
            speed: 1000,
            simulateTouch: false,
            allowTouchMove: false,
            loop: true,
            preloadImages: false,
            lazy: {
                loadPrevNext: true,
            },
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
        });
    },
    resizeSlide() {
        if (breakpointUp('lg')) {
            $('article').each(function () {
                let height = $(this).find('.block-wysiwyg').outerHeight();
                $(this).find('.swiper-slide').css('height', height);
            });
        }

    }

};

export default swiper;
