import Vue from 'vue';
import $ from 'jquery';

export default class VueContent {


    constructor() {
        this._instanceVue = null;

        this.initVue();
    }

    initVue() {

        this._instanceVue = new Vue({
            el: '#app-header',
            data: {
                menuOpen: false,
            },
            watch: {
                menuOpen(newVal, oldVal) {
                    if (newVal === true) {
                        $('html').addClass('no-scroll');
                    } else {
                        $('html').removeClass('no-scroll');
                    }
                },
            },
            computed: {},
            methods: {
                toggleMenu(){
                    if (this.menuOpen){
                        $(".menu-toggle").removeClass('is-active')
                        this.menuOpen = false
                    }else{
                        $(".menu-toggle").addClass('is-active')
                        this.menuOpen = true
                    }
                }
            },
            mounted() {
            },
        });


    }

}