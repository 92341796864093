<template>
    <div id="cookie-law" v-if="show">
        <p>We are using cookies to give you the best experience on our site. By continuing to use our website without
            changing the settings, you are agreeing to our use of cookies. For more information please click
            <span>
                <a href="/personal-data/" class="cookie-click-here"> here </a>
            </span>
        </p>
        <a id="close-cookie-banner"
           class="cookie-law"
           @click.prevent="accept()"
           href="#" rel="nofollow"
           title="Accept &amp; Close">Accept
            &amp; Close
        </a>
    </div>

</template>

<script>

    export default {

        data() {
            return {
                show: false
            };
        },
        methods: {
            accept(){
                localStorage.FB_Cookie_Alert = true
                this.show = false
            }
        },
        mounted() {
            if (!localStorage.FB_Cookie_Alert) {
                this.show = true
            } else {
                this.show = false
            }
        },
    };
</script>
