var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "contact-us" }, [
    _c("div", { staticClass: "container" }, [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-12 col-lg-3 text-content home-rte" }, [
          _c("h2", {
            staticClass: "title",
            domProps: { innerHTML: _vm._s(_vm.data.title) }
          }),
          _vm._v(" "),
          _c("h3", {
            staticClass: "sub-title",
            domProps: { innerHTML: _vm._s(_vm.data.sub_title) }
          }),
          _vm._v(" "),
          _c("div", {
            domProps: { innerHTML: _vm._s(_vm.data.contact_us_texte) }
          }),
          _vm._v(" "),
          _c("p", [
            _c("a", {
              staticClass: "btn learn-more",
              attrs: { href: _vm.data.contact_us_link },
              domProps: { innerHTML: _vm._s(_vm.data.contact_link_label) },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.countryContactCta("World", 0)
                }
              }
            })
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-12 col-lg-7 map-content" }, [
          _c("div", { staticClass: "wrap-worldmap" }, [
            _c("img", {
              staticClass: "word-map",
              attrs: { src: _vm.data.word_map, alt: "" }
            }),
            _vm._v(" "),
            _c("img", {
              staticClass: "map-europe map-area",
              attrs: { src: _vm.data.map_europe, alt: "" }
            }),
            _vm._v(" "),
            _c("img", {
              staticClass: "map-north-america map-area",
              attrs: { src: _vm.data.map_north_america, alt: "" }
            }),
            _vm._v(" "),
            _c("img", {
              staticClass: "map-oceania map-area",
              attrs: { src: _vm.data.map_oceania, alt: "" }
            }),
            _vm._v(" "),
            _c("img", {
              staticClass: "map-south-america map-area",
              attrs: { src: _vm.data.map_south_america, alt: "" }
            }),
            _vm._v(" "),
            _c("img", {
              staticClass: "map-africa map-area",
              attrs: { src: _vm.data.map_africa, alt: "" }
            }),
            _vm._v(" "),
            _c("img", {
              staticClass: "map-asia map-area",
              attrs: { src: _vm.data.map_asia, alt: "" }
            }),
            _vm._v(" "),
            _c("div", {
              staticClass: "trig-south-america trig-zone",
              attrs: { "data-map": "south-america" },
              on: {
                click: function($event) {
                  return _vm.updateCountry(0)
                }
              }
            }),
            _vm._v(" "),
            _c("div", {
              staticClass: "trig-north-america trig-zone",
              attrs: { "data-map": "north-america" },
              on: {
                click: function($event) {
                  return _vm.updateCountry(1)
                }
              }
            }),
            _vm._v(" "),
            _c("div", {
              staticClass: "trig-africa trig-zone",
              attrs: { "data-map": "africa" },
              on: {
                click: function($event) {
                  return _vm.updateCountry(2)
                }
              }
            }),
            _vm._v(" "),
            _c("div", {
              staticClass: "trig-europe trig-zone",
              attrs: { "data-map": "europe" },
              on: {
                click: function($event) {
                  return _vm.updateCountry(3)
                }
              }
            }),
            _vm._v(" "),
            _c("div", {
              staticClass: "trig-asia trig-zone",
              attrs: { "data-map": "asia" },
              on: {
                click: function($event) {
                  return _vm.updateCountry(4)
                }
              }
            }),
            _vm._v(" "),
            _c("div", {
              staticClass: "trig-oceania trig-zone",
              attrs: { "data-map": "oceania" },
              on: {
                click: function($event) {
                  return _vm.updateCountry(5)
                }
              }
            })
          ])
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "col-12 col-lg-2 contact-infos" },
          [
            _c(
              "transition",
              { attrs: { name: "slide-fade", mode: "out-in" } },
              [
                _vm.actual_contact_index === 0
                  ? _c(
                      "div",
                      {
                        key: "0",
                        staticClass: "contact-box contact-south-america"
                      },
                      [
                        _c("p", {
                          staticClass: "zone-label",
                          domProps: {
                            innerHTML: _vm._s(_vm.data.contact_infos[0].zone)
                          }
                        }),
                        _vm._v(" "),
                        _c("a", {
                          staticClass: "btn",
                          attrs: { href: _vm.data.contact_us_link },
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.data.contact_link_country_label
                            )
                          },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.countryContactCta(
                                "South America & Central America",
                                1
                              )
                            }
                          }
                        })
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.actual_contact_index === 1
                  ? _c(
                      "div",
                      {
                        key: "1",
                        staticClass: "contact-box contact-north-america"
                      },
                      [
                        _c("p", {
                          staticClass: "zone-label",
                          domProps: {
                            innerHTML: _vm._s(_vm.data.contact_infos[1].zone)
                          }
                        }),
                        _vm._v(" "),
                        _c("a", {
                          staticClass: "btn",
                          attrs: { href: _vm.data.contact_us_link },
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.data.contact_link_country_label
                            )
                          },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.countryContactCta("North America", 2)
                            }
                          }
                        })
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.actual_contact_index === 2
                  ? _c(
                      "div",
                      { key: "2", staticClass: "contact-box contact-africa" },
                      [
                        _c("p", {
                          staticClass: "zone-label",
                          domProps: {
                            innerHTML: _vm._s(_vm.data.contact_infos[2].zone)
                          }
                        }),
                        _vm._v(" "),
                        _c("a", {
                          staticClass: "btn",
                          attrs: { href: _vm.data.contact_us_link },
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.data.contact_link_country_label
                            )
                          },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.countryContactCta("Africa", 3)
                            }
                          }
                        })
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.actual_contact_index === 3
                  ? _c(
                      "div",
                      { key: "3", staticClass: "contact-box contact-europe" },
                      [
                        _c("p", {
                          staticClass: "zone-label",
                          domProps: {
                            innerHTML: _vm._s(_vm.data.contact_infos[3].zone)
                          }
                        }),
                        _vm._v(" "),
                        _c("a", {
                          staticClass: "btn",
                          attrs: { href: _vm.data.contact_us_link },
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.data.contact_link_country_label
                            )
                          },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.countryContactCta("Europe", 4)
                            }
                          }
                        })
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.actual_contact_index === 4
                  ? _c(
                      "div",
                      { key: "4", staticClass: "contact-box contact-asia" },
                      [
                        _c("p", {
                          staticClass: "zone-label",
                          domProps: {
                            innerHTML: _vm._s(_vm.data.contact_infos[4].zone)
                          }
                        }),
                        _vm._v(" "),
                        _c("a", {
                          staticClass: "btn",
                          attrs: { href: _vm.data.contact_us_link },
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.data.contact_link_country_label
                            )
                          },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.countryContactCta(
                                "Asia & Middle East",
                                5
                              )
                            }
                          }
                        })
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.actual_contact_index === 5
                  ? _c(
                      "div",
                      { key: "5", staticClass: "contact-box contact-oceania" },
                      [
                        _c("p", {
                          staticClass: "zone-label",
                          domProps: {
                            innerHTML: _vm._s(_vm.data.contact_infos[5].zone)
                          }
                        }),
                        _vm._v(" "),
                        _c("a", {
                          staticClass: "btn",
                          attrs: { href: _vm.data.contact_us_link },
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.data.contact_link_country_label
                            )
                          },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.countryContactCta("Oceania", 6)
                            }
                          }
                        })
                      ]
                    )
                  : _vm._e()
              ]
            )
          ],
          1
        )
      ])
    ]),
    _vm._v(" "),
    _vm.isPageContact
      ? _c(
          "a",
          {
            staticClass: "scroll-to",
            attrs: { href: "" },
            on: {
              click: function($event) {
                $event.preventDefault()
                return _vm.scrollTo($event)
              }
            }
          },
          [_c("i", { staticClass: "icon-intro_fleche" })]
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }