<template>
    <section class="contact-us">

        <div class="container">
            <div class="row">
                <div class="col-12 col-lg-3 text-content home-rte">
                    <h2 class="title" v-html="data.title"></h2>
                    <h3 class="sub-title" v-html="data.sub_title"></h3>
                    <div v-html="data.contact_us_texte"></div>
                    <p><a :href="data.contact_us_link" class="btn learn-more" v-html="data.contact_link_label"
                          @click.prevent="countryContactCta('World', 0)"></a></p>
                </div>

                <div class="col-12 col-lg-7 map-content">

                    <div class="wrap-worldmap">
                        <img class="word-map" :src="data.word_map" alt="">

                        <img class="map-europe map-area" :src="data.map_europe"
                             alt="">
                        <img class="map-north-america map-area"
                             :src="data.map_north_america" alt="">
                        <img class="map-oceania map-area" :src="data.map_oceania"
                             alt="">
                        <img class="map-south-america map-area"
                             :src="data.map_south_america" alt="">
                        <img class="map-africa map-area" :src="data.map_africa"
                             alt="">
                        <img class="map-asia map-area" :src="data.map_asia"
                             alt="">

                        <div class="trig-south-america trig-zone" data-map="south-america"
                             @click="updateCountry(0)"></div>
                        <div class="trig-north-america trig-zone" data-map="north-america"
                             @click="updateCountry(1)"></div>
                        <div class="trig-africa trig-zone" data-map="africa"
                             @click="updateCountry(2)"></div>
                        <div class="trig-europe trig-zone" data-map="europe"
                             @click="updateCountry(3)"></div>
                        <div class="trig-asia trig-zone" data-map="asia" @click="updateCountry(4)"></div>
                        <div class="trig-oceania trig-zone" data-map="oceania"
                             @click="updateCountry(5)"></div>

                    </div>

                </div>

                <div class="col-12 col-lg-2 contact-infos">

                    <transition name="slide-fade" mode="out-in">
                        <div class="contact-box contact-south-america" v-if="actual_contact_index === 0" key="0">
                            <p class="zone-label" v-html="data.contact_infos[0].zone"></p>
                            <a :href="data.contact_us_link" class="btn" v-html="data.contact_link_country_label"
                               @click.prevent="countryContactCta('South America & Central America', 1)"></a>
                        </div>

                        <div class="contact-box contact-north-america" v-if="actual_contact_index === 1" key="1">
                            <p class="zone-label" v-html="data.contact_infos[1].zone"></p>
                            <a :href="data.contact_us_link" class="btn" v-html="data.contact_link_country_label"
                               @click.prevent="countryContactCta('North America', 2)"></a>
                        </div>
                        <div class="contact-box contact-africa" v-if="actual_contact_index === 2" key="2">
                            <p class="zone-label" v-html="data.contact_infos[2].zone"></p>
                            <a :href="data.contact_us_link" class="btn" v-html="data.contact_link_country_label"
                               @click.prevent="countryContactCta('Africa', 3)"></a>
                        </div>
                        <div class="contact-box contact-europe" v-if="actual_contact_index === 3" key="3">
                            <p class="zone-label" v-html="data.contact_infos[3].zone"></p>
                            <a :href="data.contact_us_link" class="btn" v-html="data.contact_link_country_label"
                               @click.prevent="countryContactCta('Europe', 4)"></a>
                        </div>
                        <div class="contact-box contact-asia" v-if="actual_contact_index === 4" key="4">
                            <p class="zone-label" v-html="data.contact_infos[4].zone"></p>
                            <a :href="data.contact_us_link" class="btn" v-html="data.contact_link_country_label"
                               @click.prevent="countryContactCta('Asia & Middle East', 5)"></a>
                        </div>
                        <div class="contact-box contact-oceania" v-if="actual_contact_index === 5" key="5">
                            <p class="zone-label" v-html="data.contact_infos[5].zone"></p>
                            <a :href="data.contact_us_link" class="btn" v-html="data.contact_link_country_label"
                               @click.prevent="countryContactCta('Oceania', 6)"></a>
                        </div>
                    </transition>


                </div>
            </div>
        </div>

        <a href="" class="scroll-to" @click.prevent="scrollTo" v-if="isPageContact"><i
                class="icon-intro_fleche"></i></a>

    </section>
</template>

<script>

    import $ from 'jquery';
    import Scroll from '../libs/Scrolling';
    import {breakpointDown} from '../libs/Breakpoints';

    export default {
        props: {
            data: {
                type: Object,
                required: true,
            },
        },
        data() {
            return {
                actual_contact_index: 6
            };
        },
        computed: {
            isPageContact() {
                if ($('body').hasClass('page-template-contact')) {
                    return true;
                }
                return false;
            }
        },
        methods: {
            scrollTo() {

                Scroll.scrollTo("section.contact-form", "easeInOutCubic", 900);
            },
            updateCountry(val){
              this.actual_contact_index = val
                this.hideCf7Alert()
                if(breakpointDown('md')){
                    Scroll.scrollTo(".map-content", "easeInOutCubic", 400);
                }
            },
            countryContactCta(val, id, first_init) {

                if ($('body').hasClass('page-template-contact')) {
                    // Mise à jour du formulaire
                    $('#geographic-area').val(val);


                    if (!first_init){
                        this.hideCf7Alert()
                    }

/*                    if (breakpointDown('md')){
                        $('section.contact-form').slideDown(400)
                    }*/

                    Scroll.scrollTo("section.contact-form", "easeInOutCubic", 700);


                } else {
                    // Got to page contact
                    window.location.href = this.data.contact_us_link + '?country=' + id;

                }

            },
            hideCf7Alert(){
                $('.wpcf7-response-output').addClass('wpcf7-display-none')
            }
        },
        mounted() {

            $('.trig-zone').on('mouseenter', function () {
                let zone_name = $(this).attr('data-map');
                $('.map-area').removeClass('show');
                $('.map-' + zone_name).addClass('show');
            }).on('mouseleave', function () {
                let zone_name = $(this).attr('data-map');
                $('.map-area').removeClass('show');
            }).on('click', function () {
                let zone_name = $(this).attr('data-map');
                $('.map-area').removeClass('selected');
                $('.map-' + zone_name).addClass('selected');
                $('.contact-infos').addClass('show');
            });


            setTimeout(function () {
                if ($('.wpcf7-validation-errors').length > 0 || $('.wpcf7-mail-sent-ok').length > 0) {
                    Scroll.scrollTo("section.contact-form", "easeInOutCubic", 700);
                }
            },500)

            if (this.$route.query.country && !this.$route.hash ) {
                let val = '';

                switch (this.$route.query.country) {
                    case '0':
                        val = 'World';
                        break;
                    case '1':
                        val = 'South America & Central America';
                        break;
                    case '2':
                        val = 'North America';
                        break;
                    case '3':
                        val = 'Africa';
                        break;
                    case '4':
                        val = 'Europe';
                        break;
                    case '5':
                        val = 'Asia & Middle East';
                        break;
                    case '6':
                        val = 'Oceania';
                        break;
                }


                this.countryContactCta(val, null, true);


            }


        },
    };
</script>
