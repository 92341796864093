import ScrollMagic from 'scrollmagic';
import $ from 'jquery';
import {debounce, throttle} from 'lodash';
import {breakpointUp, breakpointDown} from './Breakpoints';
import {TweenLite} from "gsap/TweenMax";

let ScrollMagicPMU = {

    controller: new ScrollMagic.Controller(),
    autoplayDone: false,

    init: function () {

        $('body').hasClass('home') ? [this.homeVideo(), this.homeKeyAnimLG(), this.homeKeyAnimMD()] : null;

        this.keyAnimInit();

    },
    homeVideo() {

        // Scene Bouton Menu Switch
        let homeVideoScene = new ScrollMagic.Scene({
            triggerElement: '.video-container',
            triggerHook: 0.5,
            duration: 0,    // the scene should last for a scroll distance of
                            // 100px
            offset: 5,        // start this scene after scrolling for 50px
        })
        // .addIndicators()
            .addTo(this.controller)
            .on("enter", (e) => {


                if (!this.autoplayDone && breakpointUp('lg')) {
                    setTimeout(() => {
                        $(".video-container iframe")[0].src += "&autoplay=1&mute=1";
                        this.autoplayDone = true;
                    }, 500);
                }


            }).on("leave", (e) => {


                let url = $(".video-container iframe")[0].src;
                url = url.substring(0, url.length - 11); // "12345.0"

                $(".video-container iframe")[0].src = url;
                this.autoplayDone = false;


            });


    },

    keyAnimInit() {
        $(".key-anim")
        // .lettering()
            .each(function (index) {
                ScrollMagicPMU.initKeyScene('.key-' + index);
            });


    },
    initKeyScene(el) {


        return new ScrollMagic.Scene({
            triggerElement: el,
            triggerHook: 0.95,
            duration: 0,    // the scene should last for a scroll distance of
                            // 100px
            offset: 5,        // start this scene after scrolling for 50px
        })
        // .addIndicators()
            .addTo(this.controller)
            .setClassToggle(el, 'show')
            .on("enter", (e) => {

                // var title1 = new TimelineMax();
                // title1.staggerFromTo(el + " span", 0.2,
                //     {ease: Back.easeOut.config(1.7), opacity: 0, bottom: -20},
                //     {ease: Back.easeOut.config(1.7), opacity: 1, bottom: 0}, 0.05);
                //

            });

    },
    homeKeyAnimLG() {

        if (breakpointUp('lg')){
            new ScrollMagic.Scene({
                triggerElement: '.key-wrapper',
                triggerHook: 0.8,
                duration: 0,    // the scene should last for a scroll distance of
                                // 100px
                offset: 5,        // start this scene after scrolling for 50px
            })
                // .addIndicators()
                .addTo(this.controller)
                // .setClassToggle('.one-key', 'show')
                .on("enter", (e) => {

                    $('.one-key').each(function (index) {

                        let time = index * 100;
                        setTimeout(() => {
                            $(this).addClass('show');

                            ScrollMagicPMU.numberAnim(this);


                        }, time);

                    });


                })
                .on("leave", (e) => {

                    $('.one-key').removeClass('show');


                });
        }

    },
    homeKeyAnimMD() {

        if (breakpointDown('md')){


            $('.one-key').each(function (index) {

                ScrollMagicPMU.initMobileKeyScene(this);


            });


        }

    },

    initMobileKeyScene(el){


        new ScrollMagic.Scene({
            triggerElement: el,
            triggerHook: 0.8,
            duration: 0,    // the scene should last for a scroll distance of
                            // 100px
            offset: 5,        // start this scene after scrolling for 50px
        })
            // .addIndicators()
            .addTo(this.controller)
            // .setClassToggle('.one-key', 'show')
            .on("enter", (e) => {


                $(el).addClass('show');
                ScrollMagicPMU.numberAnim(el);


            })
            .on("leave", (e) => {
                $(el).removeClass('show');


            });



    },
    numberAnim(el) {

        let $element = $(el).find('.key-number');

        var Cont = {val: 0}, NewVal = $element.attr('data-number');

        TweenLite.to(Cont, 2, {
            val: NewVal, roundProps: "val", onUpdate: function () {
                $element.html(Cont.val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","));
            }
        });

    }


};

export default ScrollMagicPMU;