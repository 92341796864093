import $ from 'jquery';
import {breakpointUp} from './Breakpoints';

let Video = {

    init() {
        if($('body').hasClass('page-template-gabarit-C')){
            $('.liste-video li').each(function(){
                var iframe           = $('iframe',this);
                var iframe_src       = iframe.attr('src');
                var youtube_video_id = iframe_src.match(/youtube\.com.*(\?v=|\/embed\/)(.{11})/).pop();


                var iframe_src = iframe_src+'&title=0&byline=0&portrait=0';
                iframe.attr('src', iframe_src);
                //console.log(iframe_src);

                if (youtube_video_id.length == 11) {
                    var video_thumbnail = $('<img src="//img.youtube.com/vi/'+youtube_video_id+'/0.jpg">');
                    $('.videoWrapper',this).append(video_thumbnail);

                }

                // $('.play',this).click(function() {
                //     var iframe_src    = $(this).parent().parent().find('iframe').attr('src');
                //     var iframe_src = iframe_src+'&autoplay=1';
                //     $(this).parent().parent().find('iframe').attr('src', iframe_src);
                //     $(this).parent().parent().find('img').fadeOut(300);
                //     $(this).parent().parent().find('.overlay').addClass('hide');
                //     $(this).fadeOut(0);
                //     $(this).parent().find('.stop').fadeIn(0);

                // });

                // $('.stop',this).click(function() {
                //     var iframe_src    = $(this).parent().parent().find('iframe').attr('src');
                //     var iframe_src = iframe_src+'&autoplay=0';
                //     $(this).parent().parent().find('iframe').attr('src', iframe_src);
                //     $(this).parent().parent().find('img').fadeIn(300);
                //     $(this).parent().parent().find('.overlay').removeClass('hide');
                //     $(this).fadeOut(0);
                //     $(this).parent().find('.play').fadeIn(0);

                // });

            })


            $('.popup').each(function(){
                var vid = $('iframe',this).attr('src');
                var vid = vid+'&title=0&byline=0&portrait=0&autoplay=0';
                $(this).attr('data-vid', vid);
            })


            $('.play').click(function() {
                var vid = $(this).attr('id');
                $('.'+vid).fadeIn(300);


                var iframe  = $('.'+vid+' iframe');
                var iframe_src = iframe.attr('src');
                var iframe_src = iframe_src+'&title=0&byline=0&portrait=0&autoplay=1';
                $('.'+vid).find('iframe').attr('src', iframe_src);
            });



            $(document).click(function (event) {
                if ($(event.target).hasClass('popup')) {
                    $('.popup').fadeOut(300);

                    $('.popup').each(function(){

                        var vid = $(this).attr('data-vid');
                        $('iframe', this).attr('src', vid);
                    })

                }
            });


        }

    },

};

export default Video;
