import $ from 'jquery';
import {breakpointUp} from './Breakpoints';

let Menu = {

    init() {
        $('.head-menu .menu-item-has-children > a').each(function(){
            $(this).attr('href', '#_');

        })

        $('.head-menu .menu-item-has-children > a').click(function(){
            $(this).parent().find('.sub-menu').slideToggle(300);
        })

    },

};

export default Menu;
