import $ from 'jquery';
import Parallax from 'parallax-js';
import {breakpointUp} from './Breakpoints';

let parallaxJs = {

    parallaxInstance: null,

    init() {
        if (breakpointUp('lg')) {
            $('body').hasClass('page-template-gabarit-A') ? [this.parallaxScenes()] : null;
        }

    },
    parallaxScenes() {

        var i = 0;
        $('.scene').each(function () {
            var scene = $('#scene' + i).get(0);

            this.parallaxInstance = new Parallax(scene, {
                relativeInput: false,
                clipRelativeInput: false,
                hoverOnly: true,
            });

            i++;
        });

    }

};

export default parallaxJs;
