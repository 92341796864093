import $ from 'jquery'
export default function majTailleVideo(idvideo, ratiocible){

    var hauteurWrap = $('#'+idvideo).height()
    var largeurWrap = $('#'+idvideo).width()
    var ratio = (largeurWrap / hauteurWrap)

    var video = $('#'+idvideo+' video')

    if (ratio > ratiocible){
        //console.log('largeur')
        video.attr('style', '');
        video.css('width', '100%')

    } else {
        //console.log('hauteur')
        video.attr('style', '');
        video.css('height', '100%')
    }

}
