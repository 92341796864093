import $ from 'jquery';
import {breakpointUp} from './Breakpoints';

let Video2 = {

    init() {
        $('.popup.popup-video iframe').each(function(){
            var iframe           = $(this);
            var iframe_src       = iframe.attr('src');
            var youtube_video_id = iframe_src.match(/youtube\.com.*(\?v=|\/embed\/)(.{11})/).pop();


            var iframe_src = iframe_src+'&title=0&byline=0&portrait=0';
            iframe.attr('src', iframe_src);
        })


        $('.popup-video').each(function(){
            var vid = $('iframe',this).attr('src');
            var vid = vid+'&title=0&byline=0&portrait=0&autoplay=0';
            $(this).attr('data-vid', vid);
        })


        $('.btn-video').click(function() {
            var vid = $(this).attr('id');
            $('.'+vid).fadeIn(300);

            var iframe  = $('.'+vid+' iframe');
            var iframe_src = iframe.attr('src');
            var iframe_src = iframe_src+'&title=0&byline=0&portrait=0&autoplay=1';
            $('.'+vid).find('iframe').attr('src', iframe_src);
        });



        $(document).click(function (event) {
            if ($(event.target).hasClass('popup')) {
                $('.popup').fadeOut(300);

                $('.popup').each(function(){

                    var vid = $(this).attr('data-vid');
                    $('iframe', this).attr('src', vid);
                })

            }
        });


    },

};

export default Video2;
