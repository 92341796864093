/*

Pour connaitre la taille de l'écran en cours => breakpoint.value

=Pour conditionner quelque chose si taille écran est inférieur à une certaine taille

Ex : Taille md par exemple
	if (breakpointDown('md')){
		// Le code
	}

//////////////////////////////////////////////////
Css à insérer pour que ça fonctionne
//////////////////////////////////////////////////
body:before {
  content: "xs";
  display: none;
}
@include media-breakpoint-up(sm){
  body:before {
    content: "sm";
  }
}
@include media-breakpoint-up(md){
  body:before {
    content: "md";
  }
}
@include media-breakpoint-up(lg){
  body:before {
    content: "lg";
  }
}
@include media-breakpoint-up(xl){
  body:before {
    content: "xl";
  }
}
@include media-breakpoint-up(xxl){
  body:before {
    content: "xxl";
  }
}

*/

import $ from 'jquery';
import {debounce, throttle} from 'lodash';

export let breakpoint = {
    // Valeur actuelle taille écran
    value: null,

    init() {

        let w = $(window).width();

        $(window).resize(() => {
            if ($(window).width() === w) return;
            w = $(window).width();

            this.refreshValue();
            this.resizeEvent();
            this.resizeEventThrottle();

        });

        this.resizeEvent();
        this.resizeEventThrottle();


        $(window).bind('resizeEndThrottle', () => {
            $('html').addClass('no-transition')
        });

        $(window).bind('resizeEnd', () => {
            this.removeHTMLTransition()
        });

        this.refreshValue();


    },


    resizeEvent: debounce(function () {
        $(window).trigger('resizeEnd');
    }, 100),

    resizeEventThrottle: throttle(function () {
        $(window).trigger('resizeEndThrottle');
    }, 100),

    removeHTMLTransition: debounce(function () {
        $('html').removeClass('no-transition')
    }, 500),

    refreshValue() {
        this.value = window.getComputedStyle(document.querySelector('body'), ':before').getPropertyValue('content').replace(/\"/g, '');
    },

};

export function breakpointUp(value) {
    switch (value) {
        case 'xs':
            return !!(breakpoint.value === 'xs' | breakpoint.value === 'sm' | breakpoint.value === 'md' | breakpoint.value === 'lg' | breakpoint.value === 'xl' | breakpoint.value === 'xxl');

        case 'sm':
            return !!(breakpoint.value === 'sm' | breakpoint.value === 'md' | breakpoint.value === 'lg' | breakpoint.value === 'xl' | breakpoint.value === 'xxl');

        case 'md':
            return !!(breakpoint.value === 'md' | breakpoint.value === 'lg' | breakpoint.value === 'xl' | breakpoint.value === 'xxl');

        case 'lg':
            return !!(breakpoint.value === 'lg' | breakpoint.value === 'xl' | breakpoint.value === 'xxl');

        case 'xl':
            return !!(breakpoint.value === 'xl' | breakpoint.value === 'xxl');

        case 'xxl':
            return breakpoint.value === 'xxl';

        default:
            return false;
    }
}


export function breakpointDown(value) {
    switch (value) {
        case 'xs':
            return breakpoint.value === 'xs';

        case 'sm':
            return !!(breakpoint.value === 'xs' | breakpoint.value === 'sm');

        case 'md':
            return !!(breakpoint.value === 'xs' | breakpoint.value === 'sm' | breakpoint.value === 'md');

        case 'lg':
            return !!(breakpoint.value === 'xs' | breakpoint.value === 'sm' | breakpoint.value === 'md' | breakpoint.value === 'lg');

        case 'xl':
            return !!(breakpoint.value === 'xs' | breakpoint.value === 'sm' | breakpoint.value === 'md' | breakpoint.value === 'lg' | breakpoint.value === 'xl');

        case 'xxl':
            return !!(breakpoint.value === 'xs' | breakpoint.value === 'sm' | breakpoint.value === 'md' | breakpoint.value === 'lg' | breakpoint.value === 'xl' | breakpoint.value === 'xxl');

        default:
            return false;
    }
}


export function breakpointOnly(value) {
    switch (value) {
        case 'xs':
            return breakpoint.value === 'xs';

        case 'sm':
            return breakpoint.value === 'sm';

        case 'md':
            return breakpoint.value === 'md';

        case 'lg':
            return breakpoint.value === 'lg';

        case 'xl':
            return breakpoint.value === 'xl';

        case 'xxl':
            return breakpoint.value === 'xxl';

        default:
            return false;
    }
}






