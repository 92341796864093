import Vue from 'vue';
import router from '../router'

export default class VueContent {

    constructor() {
        this._instanceVue = null

        this.initVue();
    }

    initVue() {

        this._instanceVue = new Vue({
            el: '#app',
            router,
            data: {

            },
            computed: {},
            methods: {
            },
            mounted() {
            },
        });


    }

}