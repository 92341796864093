import $ from 'jquery';
import CheckVisible from './CheckVisible'

var KeyAnim = {
    init() {
        $('body').hasClass('home') ? [this.keyHomeAnim()] : null;
    },
    keyHomeAnim() {

        $(".one-key").mouseenter(function () {
            $(this).addClass('is-active');
        }).mouseleave(function () {
            $(this).removeClass('is-active');
        });
    },

};


export default KeyAnim;