import { render, staticRenderFns } from "./ScrollTo.vue?vue&type=template&id=1bb81549&"
import script from "./ScrollTo.vue?vue&type=script&lang=js&"
export * from "./ScrollTo.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/frenchbetting/web/app/themes/frenchbetting/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('1bb81549')) {
      api.createRecord('1bb81549', component.options)
    } else {
      api.reload('1bb81549', component.options)
    }
    module.hot.accept("./ScrollTo.vue?vue&type=template&id=1bb81549&", function () {
      api.rerender('1bb81549', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "assets/js/components/ScrollTo.vue"
export default component.exports