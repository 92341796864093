import $ from 'jquery';
import {breakpointUp} from './Breakpoints';

let HomeVideoAnim = {

    init() {

        $('body').hasClass('home') ? [this.addClasses(), this.loadVideo()] : null;





    },
    addClasses() {


        setTimeout(function () {
            $('.trig-start-anim').addClass('show')
        }, 3000)

        if (breakpointUp('lg')){
            setTimeout(function () {
                $('.wrap-loader').fadeOut(400)
            }, 500)
        }else{
            $('.wrap-loader').fadeOut()
        }
    },
    loadVideo(){
        // let url = $('#video-source').attr('data-url')
        //
        //
        // $('video source').attr('src', url)

        var video = document.querySelector('video');

        var promise = video.play();


        // promise won’t be defined in browsers that don't support promisified play()
        if (promise === undefined) {
            console.log('Promisified video play() not supported');
        } else {
            promise.then(function() {
                console.log('Video playback successfully initiated, returning a promise');
            }).catch(function(error) {
                console.log('Error initiating video playback: ', error);
            });
        }

/*        video.onloadedmetadata = function() {
            var fileName = this.currentSrc.replace(/^.*[\\/]/, '');
            document.querySelector('#videoSrc').innerHTML = 'Playing video: ' + fileName;
        };*/



    }

};

export default HomeVideoAnim;
