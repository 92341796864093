import $ from 'jquery';


let Contact_form = {
    init() {


        if($('section.contact-form').length > 0){






        }


    },

    scrollToForm(){



    }


};

export default Contact_form;