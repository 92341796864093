import Vue from 'vue';

import ScrollTo from './ScrollTo';
import WorldMap from './WorldMap';
import Anchor from './Anchor';
import Cookies from './Cookies';



Vue.component('scroll-to', ScrollTo);
Vue.component('world-map', WorldMap);
Vue.component('anchor', Anchor);
Vue.component('cookies', Cookies);