var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.show
    ? _c("div", { attrs: { id: "cookie-law" } }, [
        _vm._m(0),
        _vm._v(" "),
        _c(
          "a",
          {
            staticClass: "cookie-law",
            attrs: {
              id: "close-cookie-banner",
              href: "#",
              rel: "nofollow",
              title: "Accept & Close"
            },
            on: {
              click: function($event) {
                $event.preventDefault()
                return _vm.accept()
              }
            }
          },
          [_vm._v("Accept\n        & Close\n    ")]
        )
      ])
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(
        "We are using cookies to give you the best experience on our site. By continuing to use our website without\n        changing the settings, you are agreeing to our use of cookies. For more information please click\n        "
      ),
      _c("span", [
        _c(
          "a",
          {
            staticClass: "cookie-click-here",
            attrs: { href: "/personal-data/" }
          },
          [_vm._v(" here ")]
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }