<template>
    <div>

    </div>
</template>

<script>
    import Scrolling from '../libs/Scrolling';

    export default {

        data() {
            return {};
        },
        methods: {},
        mounted() {
            if (this.$route.query.ancre) {
                setTimeout(() => {
                    Scrolling.scrollTo('article.' + this.$route.query.ancre, "easeInOutCubic", 900);
                }, 1000);

            }
        },
    };
</script>
